<template>
    <v-app>
        <v-card
            width="500"
            outlined
            class="mx-auto my-auto"
            :loading="btnLoading"
        >
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light"
                            >Forget Password</span
                        >
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <v-form
                            ref="form"
                            v-model="valid"
                            autocomplete="chrome-off"
                        >
                            <v-text-field
                                label="email"
                                name="eamil"
                                solo
                                flat
                                prepend-inner-icon="mdi-email"
                                :rules="emailRules"
                                type="email"
                                v-model="email"
                                required
                                outlined
                                dense
                            />
                        </v-form>
                    </v-col>
                </v-row>

                <v-row v-if="requestStatus" justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <v-alert type="success" dense>
                            {{ successMsg }}
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12" align="center">
                        <v-btn
                            block
                            color="info"
                            :loading="btnLoading"
                            @click="handleForget"
                        >
                            SUBMIT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-text class="mb-5 pb-0">
                        <p
                            class="text-center font-weight grey--text overline mb-0"
                        >
                            <a @click="redirectSignin">Back to Sign In</a>
                        </p>
                    </v-card-text>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>
<script>
import { forgetPassword } from "@services/account/password";
import { snackbar } from "@components/mixins/snackbar";

export default {
    name: "ForgetPassword",
    mixins: [snackbar],
    props: {},
    data() {
        return {
            valid: true,
            email: "",
            btnLoading: false,
            requestStatus: false,
            emailRules: [
                v => !!v || "E-mail is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            successMsg: "Please find your reset password link in your mailbox."
        };
    },
    methods: {
        /**
         * Forget password post method
         * @return {[type]} [description]
         */
        async handleForget() {
            if (this.$refs.form.validate()) {
                const params = {
                    email: this.email,
                    url: window.location.origin
                };
                this.btnLoading = true;
                try {
                    const {
                        data: { msg }
                    } = await forgetPassword(params);
                    this.successMsg = msg;
                    this.btnLoading = false;
                    this.requestStatus = true;
                } catch (e) {
                    this.snackBarDanger(
                        e.response?.data?.msg || "send E-mail Error"
                    );
                    this.btnLoading = false;
                }
            }
        },
        /**
         * Go back to signin page
         * @return {[type]} [description]
         */
        redirectSignin() {
            this.$router.push({ name: "Signin" });
        }
    }
};
</script>
